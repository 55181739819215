import { TourIdentifier } from "@tsTypes/__generated__/enums/tour_identifier";

// Note: Make sure to add new flags to `app/services/flipper_gates.rb` too!
const FEATURE_FLAGS = [
  "UNCLAIMED_COMPANY_PAGE",
  "REQUEST_FOR_STARTUPS",
  "REQUEST_FOR_STARTUPS_MARKETPLACE",
  "KEYWORD_SUGGESTIONS",
  "PROPOSAL_TECHNOLOGY_READINESS_LEVEL",
  "PROPOSAL_REVIEW_SYSTEM_V2",
  "HOW_DID_YOU_HEAR_ABOUT_US",
  "SIGN_OUT_ON_ONBOARDING",
  "SESSION_TIMEOUT",
  "OPPORTUNITY_DASHBOARD_V2",
  "COMPANY_MARKETPLACE_FILTER",
  "UNIVERSITY_ADMIN_DISCIPLINES",
  "NEW_PROPOSAL_FORM_UI_WITH_REQUIREMENTS",
  "OLD_PROPOSAL_FORM_REQUEST_REQUIREMENTS",
  "OLD_PROPOSAL_FORM_REQUEST_SOLUTIONS",
  "PROPOSAL_REVIEW_SYSTEM_SOLUTIONS_OF_INTEREST",
  "PROPOSAL_REVIEW_SYSTEM_REQUIREMENTS",
  "CANCEL_SIGN_UP",
  "UPDATE_EMAIL_IN_ONBOARDING",
  "GOOGLE_SCHOLAR_ONBOARDING_STEP",
  "RFS_FILTER",
  "COMPANY_PAGE_RFS",
  "PRS_EVALUATION",
  "PEPSICO_REVIEWER_DASHBOARD_SORTING",
  "HIDE_COMMENTER_PERSONAL_NOTES",
  "RFP_FORM_V2",
  "PRS_AI_SEARCH",
  "REQUEST_FOR_EXPERTS",
  "MARKETPLACE_V2",
  "ONBOARDING_V2",
  "MARKETPLACE_PARTNER_TYPE_FILTER",
  "RFE_CONTRIBUTIONS",
  "COMMERCIAL_APPLICATIONS",
] as const;

// Export object of { [name as symbol]: boolean }
export default FEATURE_FLAGS.reduce(
  (obj, flag) => ({ ...obj, [flag]: gon.feature_flags?.includes(flag) }),
  {}
) as { [F in (typeof FEATURE_FLAGS)[number]]: boolean };

export const isTourEnabled = (tour: TourIdentifier): boolean =>
  gon.feature_flags?.includes(`TOUR_${tour}`);

import Icon, { IconName } from "@components/library/Icon/Icon";
import { COLORS, FONTS, STYLES } from "@constants";
import styled from "styled-components";
import { BaseButtonProps } from "./Button";

interface Props extends BaseButtonProps {
  text: string;
  size?: "xs" | "sm" | "md";
  color?: string;
  iconName?: IconName | null;
  iconPosition?: "right" | "left";
  width?: string;
}

const TextButton = ({
  text,
  size = "md",
  color = COLORS.HALO_BLUE,
  iconName = "Chevron Down",
  iconPosition = "right",
  margin,
  id,
  name,
  type = "button",
  disabled,
  width,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  onBlur,
  "data-testid": dataTestId,
}: Props) => {
  return (
    <Button
      size={size}
      color={color}
      margin={margin}
      iconPosition={iconPosition}
      id={id}
      name={name}
      type={type}
      disabled={disabled}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      onBlur={onBlur}
      data-testid={dataTestId}
      width={width}
    >
      <span>{text}</span>
      {iconName && (
        <Icon
          name={iconName}
          size={size === "md" ? "md" : "xs"}
          color={color}
          margin={iconPosition === "right" ? "0 0 0 4px" : "0 4px 0 0"}
        />
      )}
    </Button>
  );
};

export default TextButton;

const Button = styled.button`
  display: flex;
  flex-direction: ${({ iconPosition }) => (iconPosition === "right" ? "row" : "row-reverse")};
  align-items: center;
  height: ${({ size }) => (size === "sm" ? 16 : 24)}px;
  ${({ width }) =>
    width
      ? `
        width: ${width}; 
        span {
          ${STYLES.ONE_LINE_ALT};
        }
      `
      : "fit-content"};
  ${({ margin }) => margin && `margin: ${margin}`};
  padding: 0;
  ${({ size }) => {
    if (size === "xs") return FONTS.BUTTON_4;
    if (size === "sm") return FONTS.BUTTON_3;
    return FONTS.BUTTON_2;
  }};
  color: ${({ color }) => (color ? color : COLORS.HALO_BLUE)};
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  &:hover {
    text-decoration: underline ${({ color }) => color};
    text-underline-offset: 4px;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: 0.4;
  }
`;
